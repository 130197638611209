import { Box } from "@mui/material";
import ListComponent from "./ListComponent";

const Popover = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "24px",
        width: "95%",
        margin: "auto",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        gap: {
          xs: "16px",
          sm: "0px",
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "49%",
          },
        }}
      >
        <ListComponent title={"Training"} />
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "49%",
          },
        }}
      >
        <ListComponent title={"Forums"} />
      </Box>
    </Box>
  );
};

export default Popover;
