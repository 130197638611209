import React from "react";
import { useSelector } from "react-redux";

import DefaultPage from "../Screens/DefaultPage";
import ExternalForm from "../Screens/ExternalAdminInvitationForm/ExternalForm";
import InternalForm from "../Screens/InternalAdminInvitationForm/InternalForm";
import InvitationHistory from "../Screens/InvitationHistory/InvitationHistory";
import Spotlight from "../Screens/Spotlight/Spotlight";
import Subjects from "../Screens/Subjects/Subjects";
export default function RenderPage({ props }) {
  const { selectedPage } = useSelector((store) => store.adminpage);

  const renderPage = () => {
    switch (selectedPage) {
      case "Send Invitation: External Partners":
        return <ExternalForm />;
      case "Send Invitation: Internal Partners":
        return <InternalForm />;
      case "Invitation History":
        return <InvitationHistory />;
      case "In the spotlight":
        return <Spotlight {...(props || {})} />;
      case "Subjects":
        return <Subjects />;
      default:
        return <DefaultPage />;
    }
  };
  return <div className="w-full">{renderPage()}</div>;
}
