import React from "react";
import { Box, Typography } from "@mui/material";
import image from "../../../../assets/spotlight1.png";
import SpotlightCardItem from "./SpotlightCardItem";
import DividerComponent from "../CommonComponent/DividerComponent";
import { Button } from "pepsico-ds";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";

const spotlightArray = [
  {
    image: image,
    title: "2024 Frito-Forum #3 - Digital Journey",
    subtitle:
      "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and...",
  },
  {
    image: image,
    title: "2024 Frito-Forum #3 - Digital Journey",
    subtitle:
      "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and...",
  },
  {
    image: image,
    title: "2024 Frito-Forum #3 - Digital Journey",
    subtitle:
      "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and...",
  },
  {
    image: image,
    title: "2024 Frito-Forum #3 - Digital Journey",
    subtitle:
      "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and...",
  },
  {
    image: image,
    title: "2024 Frito-Forum #3 - Digital Journey",
    subtitle:
      "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and...",
  },
  {
    image: image,
    title: "2024 Frito-Forum #3 - Digital Journey",
    subtitle:
      "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and...",
  },
  {
    image: image,
    title: "2024 Frito-Forum #3 - Digital Journey",
    subtitle:
      "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and...",
  },
  {
    image: image,
    title: "2024 Frito-Forum #3 - Digital Journey",
    subtitle:
      "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and...",
  },
  {
    image: image,
    title: "2024 Frito-Forum #3 - Digital Journey",
    subtitle:
      "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and...",
  },
];

export default function Spotlight({ flexDirection, width } = {}) {
  const isDarkTheme = useSelector ((state) => state.theme);
  return (
    <div className="flex flex-col gap-4">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: "var(--Spacing-spacing-16, 16px)",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-16, 16px)",
          borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
          boxShadow:
            "var(--Elevation-X-Elevation-03, 0px) var(--Elevation-Y-Elevation-03, 2px) var(--Elevation-Blur-Elevation-03, 24px) var(--Elevation-Spread-Elevation-03, 8px) var(--Elevation-Colors-Elevation-03, rgba(0, 92, 188, 0.12))",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            alignSelf: "stretch",
          }}
        >
          <Typography
            sx={{
              color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "170%",
            }}
          >
            Images
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "16px",
            width: "100%",
            flexShrink: 0,
            flexDirection: flexDirection,
          }}
        >
          {spotlightArray.map((item, index) => (
            <SpotlightCardItem
              title={item.title}
              image={item.image}
              subtitle={item.subtitle}
              key={index}
              width={width}
            />
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "16px",
          paddingX: "16px",
        }}
      >
        <DividerComponent />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "var(--Spacing-spacing-16, 16px)",
          }}
        >
          <Button size="large" text="Cancel" variant="secondary" />
          <Button size="large" text="Save" variant="primary" />
        </Box>
      </Box>
    </div>
  );
}
