import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ align: "" }, { align: "center" }, { align: "right" }],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

const optimizedStyles = (isDarkTheme) =>  `
 .editor-container {
    position: relative;
    width:100%;
  }

  .editor-container .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    box-shadow: none; 
  }

  .editor-container .ql-container {
    height: 200px; 
    width: 100%;
    border: 1px solid #C0C0C0; 
    border-radius:4px;
    box-shadow: none;
    outline: none; 
    color: ${isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1};
  }
.editor-container .ql-container .ql-editor.ql-blank::before {
    color: ${isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4};
  }
  .editor-container .ql-container:before {
  content: '';
  position: absolute;
  top: -1px; 
  left: 0;
  right: 0;
  height: 1px;
  background-color: #C0C0C0;
  border-radius: 4px;
}
`;




const StepFourForm = () => {
  const [documentNotes, setDocumentNotes] = useState("");
  const [summary, setSummary] = useState("");
  const [translation, setTranslation] = useState("");
  const [translate, setTranslate] = useState("no");
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const handleChange = (setter) => (event) => setter(event.target.value);
  const handleQuillChange = (setter) => (value) => setter(value);
  const isDarkTheme = useSelector((state) => state.theme);
  if (typeof window !== "undefined") {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = optimizedStyles(isDarkTheme);
    document.head.appendChild(styleSheet);
  }
  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
    width: "100%", /* Ensure the outer box also takes full width */
  };

  const renderEditor = (value, onChange, placeholder) => (
    <Box className="editor-container">
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={quillModules}
        style={{ height: "100%", width: "100%" }} /* Full width and height */
      />
    </Box>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}>
      {/* Document section */}
      <Box sx={baseBoxStyle}>
        <Typography sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, mb: 1 }}>
          Document Notes
        </Typography>
        {renderEditor(
          documentNotes,
          handleQuillChange(setDocumentNotes),
          "Type here"
        )}
      </Box>

      {/* Summary section */}
      <Box sx={baseBoxStyle}>
        <Typography sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, mb: 1 }}>
          Summary
        </Typography>
        {renderEditor(summary, handleQuillChange(setSummary), "Type here")}
      </Box>

      {/* Translation section */}
      <Box sx={baseBoxStyle}>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <Typography sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, mb: 1 }}>
            Language Translation
          </Typography>
          <RadioGroup
            column
            value={translate}
            onChange={handleChange(setTranslate)}
          >
            <FormControlLabel value="yes" control={<Radio sx={{
          color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
        }} />} label="Yes"  sx={{
          color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
        }} />
            <FormControlLabel value="no" control={<Radio sx={{
          color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
        }}/>} label="No" sx={{
          color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
        }} />
          </RadioGroup>
        </FormControl>

        {translate === "yes" && (
          <>
            <FormControl sx={{ mb: 2 , width: { xs: "100%", md: "40%" }}}>
              <Typography sx={{ fontWeight: 700,  color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, mb: 1 }}>
                Select Language
              </Typography>
              <Select
                id="select-language"
                value={selectedLanguage}
                onChange={handleChange(setSelectedLanguage)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3,
                      "& .MuiMenuItem-root": {
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, 
                "&:hover": {
                  backgroundColor: isDarkTheme ? darkTheme.dropdownHoverColorLayer3 : lightTheme.dropdownHoverColorLayer3, 
                },
              },
                    },
                  },
                }}
                sx={{ backgroundColor: isDarkTheme  ? darkTheme.backgroundColorLayer2 : lightTheme.backgroundColorLayer2 , height: 40, "& .MuiSelect-icon": {
                  color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
                },
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
                border: '1px solid', 
                borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
                "&:hover": {
                  borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
                },
              }}
                displayEmpty
              >
                <MenuItem value="">Select a language</MenuItem>
                <MenuItem value="english">English</MenuItem>
                <MenuItem value="spanish">Spanish</MenuItem>
                <MenuItem value="french">French</MenuItem>
              </Select>
            </FormControl>

            <Typography sx={{ fontWeight: 700,  color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, mb: 1 }}>
              Translation
            </Typography>
            <Box className="editor-container">
              {renderEditor(
                translation,
                handleQuillChange(setTranslation),
                "Type here"
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default StepFourForm;
