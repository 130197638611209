import React, { useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { Icon } from "pepsico-ds";
import LoginIcon from "@mui/icons-material/Login";
import PreferencesDrawer from "./PreferencesDrawer";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

const DropdownMenu = () => {
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isDarkTheme = useSelector((state) => state.theme);
  
  const handleClick = (event) => {
    setDropdownAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setDropdownAnchor(null);
  };

  const handlePreferencesClick = () => {
    setDrawerOpen(true);
    handleClose();
  };

  return (
    <>
      <Icon
        alt="Dropdown"
        icon="arrow_drop_down"
        size="medium"
        onClick={handleClick}
        style={{color:isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1}}
      />

      <Menu
        id="dropdown-menu"
        anchorEl={dropdownAnchor}
        open={Boolean(dropdownAnchor)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "20px",
          borderRadius: 4, 
          "& .MuiPaper-root": {
      backgroundColor: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3, 
      padding: "10px",
    }, }}
      >
        <MenuItem onClick={handlePreferencesClick} selected={true} sx={{color:isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1}}>
          Preferences
        </MenuItem>
        <MenuItem onClick={handleClose} sx={{color:isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            Signout
            <LoginIcon fontSize="small" />
          </Box>
        </MenuItem>
      </Menu>

      <PreferencesDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
};

export default DropdownMenu;
