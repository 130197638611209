export const commonStyles = {
  boxStyles: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  inputContainer: {
    display: "flex",
    width: "100%",
    maxWidth: "400px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "var(--Spacing-spacing-8, 8px)",
  },
  inputBase: {
    width: "100%",
    maxWidth: "400px",
  },
  textTypography: {
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-XSmall, 12px)",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "var(--Line-Height-XSmall, 18px)",
  },
  buttonTypography: {
    color: "var(--Tokens-Button-Borderless, #005CBC)",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-Body, 14px)",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-Body, 22px)",
  },
  flexContainer: {
    display: "flex",
    padding: "16px",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: {
      xs: "flex-end",
      md: "space-between",
    },
    gap: {
      xs: "16px",
      md: "0",
    },
    alignItems: {
      xs: "end",
      md: "center",
    },
    alignSelf: "stretch",
    borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
    boxShadow:
      "var(--Elevation-X-Elevation-03, 0px) " +
      "var(--Elevation-Y-Elevation-03, 2px) " +
      "var(--Elevation-Blur-Elevation-03, 24px) " +
      "var(--Elevation-Spread-Elevation-03, 8px) " +
      "var(--Elevation-Colors-Elevation-03, rgba(0, 92, 188, 0.12))",
  },
};
