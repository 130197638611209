import React from "react";
import { Typography, Box } from "@mui/material";
import { Button } from "pepsico-ds";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";

export default function SpotlightCardItem({ title, subtitle, image, width }) {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: "flex",
        width: width ? width : "32%",
        maxHeight: "100%",
        minHeight: "422px",
        flexDirection: "column",
        gap: "18px",
        padding: "14px",
        alignItems: "flex-start",
        background: isDarkTheme ? darkTheme.backgroundColorLayer2 : lightTheme.backgroundColorLayer2,
        boxShadow: "4px 0px 24px -2px rgba(0, 92, 188, 0.08)",
      }}
    >
      <Box
        sx={{
          height: "25vh",
          width: "100%",
          flexShrink: 0,
          alignSelf: "stretch",
          borderRadius: "4px",
          backgroundImage: `url(${image})`,
          backgroundPosition: "50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "14px",
        }}
      >
        <Typography
          sx={{
            color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, 
            fontFeatureSettings: "'liga' off, 'clig' off", // Font feature settings
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)", // Font family
            fontSize: {
              xs: "13px", // Font size for extra small screens
              sm: "24px", // Font size for small screens and up
            },
            fontStyle: "normal", // Font style
            fontWeight: 700, // Font weight
            lineHeight: "var(--Line-Height-H4, 25px)", // Line height
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2, 
            fontFeatureSettings: "'liga' off, 'clig' off", // Font feature settings
            fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family
            fontSize: {
              xs: "14px", // Font size for extra small screens
              sm: "16px", // Font size for small screens and up
            },
            fontStyle: "normal", // Font style
            fontWeight: 500, // Font weight
            lineHeight: "var(--Line-Height-Body, 22px)", // Line height
          }}
        >
          {subtitle}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "24px",
          width: "100%",
        }}
      >
        <button>
          <Typography
            sx={{
              color: "var(--Tokens-Button-Borderless, #005CBC)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Body, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Body, 22px)",
            }}
          >
            Delete article
          </Typography>
        </button>
        <Button size="large" text="Swap article" variant="secondary" />
      </Box>
    </Box>
  );
}
