import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { Divider } from "pepsico-ds";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { useSelector } from "react-redux";

const ExternalStepTwoForm = () => {
  const [visibility, setVisibility] = useState("internal");
  const [contentLevel, setContentLevel] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const options = [
    "Subject 1",
    "Subject 2",
    "Subject 3",
    "Subject 4",
    "Subject 4",
    "Subject 5",
    "Subject 6",
  ];

  // Common section styles
  const sectionStyles = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      {/* Section 1 */}
      <Box sx={sectionStyles}>
        <Typography sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>Title</Typography>
        <TextField
          placeholder="Add Title"
          size="small"
          sx={{
            borderRadius: "4px",
            width: { xs: "100%", md: "40%" },
           "& .MuiInputBase-input::placeholder": {
            color: isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4
          },
          "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
              },
              "&:hover fieldset": {
                borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
              },
              "&.Mui-focused fieldset": {
                borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
              },
            },
    "& .MuiInputBase-input": {
      color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
    },
          }}
        />
      </Box>

      {/* Section 2 */}
      <Box sx={sectionStyles}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>
            Visibility
          </FormLabel>
          <RadioGroup
            value={visibility}
            onChange={(e) => setVisibility(e.target.value)}
            sx={{
              flexDirection: { xs: "column", md: "row" }, 
            }}
          >
            <FormControlLabel
              value="internal"
              control={<Radio sx={{
                color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
              }}/>}
              label="Internal"
              sx={{
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="external"
              control={<Radio sx={{
                color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
              }} />}
              label="External"
              sx={{
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="restricted"
              control={<Radio sx={{
                color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
              }} />}
              label="Restricted"
              sx={{
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              }}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {/* Section 3 */}
      <Box sx={sectionStyles}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>
            Content Level
          </FormLabel>
          <RadioGroup
            value={contentLevel}
            onChange={(e) => setContentLevel(e.target.value)}
            sx={{
              flexDirection: { xs: "column", md: "row" }, 
            }}
          >
            <FormControlLabel value="basic" control={<Radio sx={{
                color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
              }} />} label="Basic" sx={{
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              }} />
            <FormControlLabel
              value="Level1"
              control={<Radio sx={{
                color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
              }} />}
              label="Level 1"
              sx={{
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="Level2"
              control={<Radio sx={{
                color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
              }}/>}
              label="Level 2"
              sx={{
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="Level3"
              control={<Radio sx={{
                color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
              }}/>}
              label="Level 3"
              sx={{
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="advanced"
              control={<Radio sx={{
                color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
              }}/>}
              label="Advanced"
              sx={{
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              }}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {/* Section 4 */}
      <Box sx={sectionStyles}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel>
            <Typography sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>
              Select subject(s):
            </Typography>
          </FormLabel>
          <Box
            sx={{
              maxHeight: 200,
              width: { xs: "100%", md: "40%" },
              overflowY: "auto",
              border: "1px solid #ccc",
              padding: 1,
              marginTop: 1,
              backgroundColor: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3,
              borderRadius: 1,
              scrollbarWidth: "thin",
              scrollbarColor: "#888 #f1f1f1",
            }}
          >
            <FormGroup>
              {options.map((option, index) => (
                <Box key={option}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedOptions.includes(option)}
                        onChange={handleChange}
                        value={option}
                        sx={{ color: isDarkTheme ? darkTheme.checkBoxLayer1 : lightTheme.checkBoxLayer1}}
                      />
                    }
                    label={option}
                    sx={{
                      backgroundColor: selectedOptions.includes(option)
                        ? isDarkTheme ? darkTheme.selectedBoxLayer1 : lightTheme.selectedBoxLayer1
                        : "transparent",
                      borderRadius: 1,
                      width: "100%",
                      color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1
                    }}
                  />
                  {index < options.length - 1 && <Divider />}
                </Box>
              ))}
            </FormGroup>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ExternalStepTwoForm;
