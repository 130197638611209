import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ align: "" }, { align: "center" }, { align: "right" }],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

const optimizedStyles  = (isDarkTheme) =>  `
 .editor-container {
    position: relative;
    width:100%;
  }

  .editor-container .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    box-shadow: none; 
    color: ${isDarkTheme && 'white'}
  }

  .editor-container .ql-container {
    height: 200px; 
    width: 100%;
    border: 1px solid #C0C0C0; 
    border-radius:4px;
    box-shadow: none;
    outline: none; 
    color: ${isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1};
  }
.editor-container .ql-container .ql-editor.ql-blank::before {
    color: ${isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4};
  }
  .editor-container .ql-container:before {
  content: '';
  position: absolute;
  top: -1px; 
  left: 0;
  right: 0;
  height: 1px;
  background-color: #C0C0C0;
  border-radius: 4px;
}
`;



const ExternalStepThreeForm = () => {
  const [documentNotes, setDocumentNotes] = useState("");
  const handleQuillChange = (setter) => (value) => setter(value);
  const isDarkTheme = useSelector((state) => state.theme);
  if (typeof window !== "undefined") {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = optimizedStyles(isDarkTheme);
    document.head.appendChild(styleSheet);
  }
  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
    width: "100%", 
  };

  const renderEditor = (value, onChange, placeholder) => (
    <Box className="editor-container">
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={quillModules}
        style={{ height: "100%", width: "100%" }} 
      />
    </Box>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}>
      {/* Document section */}
      <Box sx={baseBoxStyle}>
        <Typography sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 , mb: 1 }}>
          Document Notes
        </Typography>
        {renderEditor(
          documentNotes,
          handleQuillChange(setDocumentNotes),
          "Type here"
        )}
      </Box>
    </Box>
  );
};

export default ExternalStepThreeForm;
