import React from "react";
import { Box, Autocomplete, TextField, Typography, Popper } from "@mui/material";
import DividerComponent from "../CommonComponent/DividerComponent";
import AdminFormField from "../CommonComponent/AdminFormField";
import { Button } from "pepsico-ds";
import { commonStyles } from "./SubjectStyles";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
export default function Subjects() {
  const options = ["The Origin of Potatoes and Potato 101"];
  const isDarkTheme = useSelector((state) => state.theme);
  const HandleChange = (event, newValue) => {
    console.log(newValue);
  };

  return (
    <Box sx={commonStyles.boxStyles}>
      <Box sx={commonStyles.inputContainer}>
        <Box
          sx={{ display: "flex", alignItems: "center", alignSelf: "stretch" }}
        >
          <Typography sx={{ ...commonStyles.textTypography, color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2  }}>
            Select Subject
          </Typography>
        </Box>
        <Box sx={commonStyles.inputContainer}>
          <Autocomplete
            onChange={HandleChange}
            options={options}
            getOptionLabel={(option) => option}
            PopperComponent={(props) => (
              <Popper
                {...props}
                sx={{
                  "& .MuiAutocomplete-listbox": {
                    background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3,
                    color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
                    "& .MuiAutocomplete-option:hover": {
                      backgroundColor: isDarkTheme ? darkTheme.dropdownHoverColorLayer3 : lightTheme.dropdownHoverColorLayer3,
                    },
                  },
                }}
              />
            )}
            sx={{...commonStyles.inputBase,  "& .MuiInputBase-root": {
              height: "32px",
              color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
              },  
              "&:hover fieldset": {
                borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
              },
              "& .MuiAutocomplete-clearIndicator": {
              color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1, 
            },
            "& .MuiAutocomplete-listbox": {
              color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              "& .MuiAutocomplete-option:hover": {
                backgroundColor: 'red'
              },
            },
          }}}
            renderInput={(params) => <TextField {...params} />}           
          />
        </Box>
      </Box>

      <DividerComponent />

      <Box sx={{...commonStyles.flexContainer, color: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3}}>
        <AdminFormField label={"Subject Name"} />
        <button>
          <Typography sx={commonStyles.buttonTypography}>
            Delete subject
          </Typography>
        </button>
      </Box>

      <Box sx={commonStyles.boxStyles} style={{ height: "40vh" }}>
        <DividerComponent />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "var(--Spacing-spacing-16, 16px)",
          }}
        >
          <Button size="large" text="Cancel" variant="secondary" />
          <Button size="large" text="Save" variant="primary" />
        </Box>
      </Box>
    </Box>
  );
}
