// src/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import navReducer from "../slices/navSlice";
import adminPageReducer from "../slices/adminPageSlice";
import viewReducer from "../slices/viewSlice";
import darkThemeReducer from "../slices/themeSlice"
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "root", 
  storage, 
  whitelist: ["theme"],
};

const rootReducer = combineReducers({
  nav: navReducer,
  adminpage: adminPageReducer,
  view: viewReducer,
  theme: darkThemeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
