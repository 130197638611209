import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  Collapse,
  List,
  ListItemButton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

const MobileNavListItem = ({ item, selectedItem, handleItemClick }) => {
  const [open, setOpen] = useState(false);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleToggle = () => {
    setOpen(!open);
    handleItemClick(item.label); // Optional: highlight main item when dropdown toggles
  };

  const handleChildClick = (child) => {
    handleItemClick(child.label);
    setOpen(false); // Close the dropdown on child click
  };

  const isSelected = selectedItem === item.label;

  return (
    <>
      {item.children ? (
        <ListItem
          button
          onClick={handleToggle}
          sx={{
            backgroundColor: isSelected
            ? (isDarkTheme ? darkTheme.cardBackgroundColorLayer2 : lightTheme.cardBackgroundColorLayer2)
            : "transparent",
            color:  isSelected
            ? (isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1)
            : (isDarkTheme ? '#B0B0B0' : lightTheme.textColorLayer2),
            boxShadow: isSelected
              ? "5px 0px 0px 0px var(--Tokens-Interaction-Active, #005CBC) inset"
              : "none",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-Small, 14px)",
            fontWeight: 400,
            lineHeight: "var(--Line-Height-Small, 20px)",
            '&:hover': {
    backgroundColor: isSelected
      ? (isDarkTheme ? darkTheme.dropdownHoverColorLayer3 : lightTheme.dropdownHoverColorLayer3)
      : (isDarkTheme ? darkTheme.dropdownHoverColorLayer3 : lightTheme.dropdownHoverColorLayer3),
  },
          }}
        >
          <ListItemText primary={item.label} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      ) : (
        <ListItem
          button
          component={Link}
          to={item.url}
          onClick={() => handleItemClick(item.label)}
          sx={{
            backgroundColor: isSelected
            ? (isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1)
            : "transparent",
            color: isSelected ? "#005CBC" : "#3A3A3A",
            boxShadow: isSelected
              ? "5px 0px 0px 0px var(--Tokens-Interaction-Active, #005CBC) inset"
              : "none",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-Small, 14px)",
            fontWeight: 400,
            lineHeight: "var(--Line-Height-Small, 20px)",
          }}
        >
          <ListItemText primary={item.label} sx={{ color: isDarkTheme ? '#B0B0B0' : lightTheme.textColorLayer2 }}/>
        </ListItem>
      )}

      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((child, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={child.url}
                sx={{ pl: 4 }}
                onClick={() => handleChildClick(child)}
              >
                <ListItemText primary={child.label} sx={{ color: isDarkTheme ? '#B0B0B0' : lightTheme.textColorLayer2 }} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default MobileNavListItem;
