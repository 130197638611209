import { Box, Typography } from "@mui/material";
import React from "react";
import DropDown from "../DropDown/DropDown";
import RenderPage from "../RenderPage/RenderPage";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";
export default function Administration() {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <div className="px-[83px] py-4" style={{background: isDarkTheme ? darkTheme.backgroundColorLayer1 : lightTheme.backgroundColorLayer1}}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: "var(--Spacing-spacing-24, 24px)",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-24, 24px)",
          flexShrink: 0,
          backgroundColor: isDarkTheme ? darkTheme.backgroundColorLayer2 : lightTheme.backgroundColorLayer2,
          boxSizing: "border-box",
          overflowY: "auto",
          boxShadow:
            "var(--Elevation-X-Elevation-04, 4px) var(--Elevation-Y-Elevation-04, 0px) var(--Elevation-Blur-Elevation-04, 24px) var(--Elevation-Spread-Elevation-04, -2px) var(--Elevation-Colors-Elevation-04, rgba(0, 92, 188, 0.08))",
        }}
      >
        <Typography
          sx={{
            alignSelf: "stretch",
            color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
            fontFeatureSettings: "'liga' off, 'clig' off", // Disabling specific font features
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)", // Font family
            fontSize: "var(--Font-Size-H5, 24px)", // Font size for H5
            fontStyle: "normal", // Normal style
            fontWeight: 700, // Bold font weight
            lineHeight: "var(--Line-Height-H5, 32px)", // Line height
          }}
        >
          Administration
        </Typography>
        <Box
          sx={{
            display: "flex", // Set display to flex
            flexDirection: "column", // Flex direction set to column
            alignItems: "flex-start", // Align items to start
            alignSelf: "stretch", // Stretch the box to fill available space
            background: "#DFDFDF",
          }}
        />
        <DropDown width={"100%"} />
        <Box
          sx={{
            display: "flex", // Set display to flex
            flexDirection: "column", // Flex direction set to column
            alignItems: "flex-start", // Align items to start
            alignSelf: "stretch", // Stretch the box to fill available space
            background: "#DFDFDF",
            height: "1px",
          }}
        />
        <RenderPage />
      </Box>
    </div>
  );
}
