import React from "react";
import { Box } from "@mui/material";
import NavigationLink from "./NavigationLink";
import NavigationDropDown from "./NavigationDropDown";
import {
  uploadNavigation,
  potatoVariety,
  sustainabilityNavigation,
} from "./navigation";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { useSelector } from "react-redux";
const NavigationBar = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  return(
  <Box
    sx={{
      display: { xs: "none", md: "none", lg: "flex" },
      padding: "8px 24px",
      alignItems: "center",
      gap: "24px",
      alignSelf: "stretch",
      background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3,
    }}
  >
    <Box sx={{ display: "flex", gap: 2, fontSize: "14px", color: "#616161" }}>
      <NavigationLink to="/library" label="Library" />
      <NavigationDropDown
        menuItems={uploadNavigation}
        label={"Upload Content"}
      />
      <NavigationLink to="/frito-forum" label="Frito Forum" />
      <NavigationDropDown label={"Potato variety"} menuItems={potatoVariety} />

      <NavigationLink
        to="/potato-protection-status"
        label="Potato protection status"
      />
      <NavigationLink to="/global-trial" label="Global trial" />
      <NavigationDropDown
        label={"Sustainability"}
        menuItems={sustainabilityNavigation}
      />
      <NavigationLink
        to="/subject-matter-experts"
        label="Subject matter experts"
      />
      <NavigationLink to="/curator" label="Curator" />
      <NavigationLink to="/administration" label="Administration" />
    </Box>
  </Box>
)
}

export default NavigationBar;
