import { Button } from "pepsico-ds";
import "./HomePageCarousal.css";

const OverlayContent = () => {
  return (
    <div className="relative text-white   rounded w-full  md:w-[493px] md:h-[216px] flex flex-col gap-6  items-start justify-start">
      {/* Text centered for small screens, left-aligned for larger screens */}
      <span className="font-bold text-3xl  flex flex-wrap  md:text-left">
        Building a stronger, more sustainable future
      </span>
      <span className="font-serif text-[17px] flex flex-wrap  md:text-left">
        By becoming better ourselves, we can help build a stronger, more
        sustainable future for us all.
      </span>
      <div className="flex flex-row gap-4">
        <Button
          style={{ background: "white" }}
          className="px-12 md:absolute md:bottom-0"
          size="large"
          text="Learn more"
          variant="secondary"
          iconTrailing="arrow_right_alt"
        />
      </div>
    </div>
  );
};

export default OverlayContent;
