// components/AvatarComponent.js
import Box from "@mui/material/Box";
import { Icon, Avatar } from "pepsico-ds";

const AvatarComponent = () => (
  <Box>
    <Icon
      alt="User avatar"
      svg={
        <Avatar
          imageUrl="https://randomuser.me/api/portraits/men/1.jpg"
          name="Jon Do"
          size="large"
        />
      }
    />
  </Box>
);

export default AvatarComponent;
