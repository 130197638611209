import React from "react";
import { Box, Typography } from "@mui/material";
import DropDown from "../DropDown/DropDown";
import DefaultPage from "../Screens/DefaultPage";
import { useSelector } from "react-redux";
import RenderPage from "../RenderPage/RenderPage";
import { darkTheme, lightTheme } from "../../../theme/Theme";
export default function MobileAdmin() {
  // const renderPage = () => {
  //   switch (selectedPage) {
  //     case "spotlight":
  //       return <AdminSpotlight />;

  //     default:
  //       return <DefaultPage />;
  //   }
  // };
  const isDarkTheme = useSelector((state) => state.theme);
  const props = {
    flexDirection: "column",
    width: "100%",
  };
  return (
    <Box
      sx={{
        display: "flex", // Flexbox layout
        width: "100%", // Full width
        flexDirection: "column", // Stack elements vertically
        padding: "var(--Spacing-spacing-24, 24px)", // Padding from custom variable
        backgroundColor: isDarkTheme ? darkTheme.backgroundColorLayer2 : lightTheme.backgroundColorLayer2,
        boxShadow:
          "var(--Elevation-X-Elevation-04, 4px) var(--Elevation-Y-Elevation-04, 0px) var(--Elevation-Blur-Elevation-04, 24px) var(--Elevation-Spread-Elevation-04, -2px) var(--Elevation-Colors-Elevation-04, rgba(0, 92, 188, 0.08))", // Box shadow
      }}
    >
      <div className="flex flex-col gap-6 w-full">
        <DropDown />
        <Box
          sx={{
            display: "flex", // Set display to flex
            flexDirection: "column", // Flex direction set to column
            alignItems: "flex-start", // Align items to start
            alignSelf: "stretch", // Stretch the box to fill available space
            background: "#DFDFDF",
            height: "1px", // This might be intended for a divider
          }}
        />
        <Box>
          <RenderPage props={props} />
        </Box>
      </div>
    </Box>
  );
}
