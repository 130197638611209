import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
} from "@mui/material";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

const PROJECT_OPTIONS = [
  { value: "project1", label: "Project 1" },
  { value: "project2", label: "Project 2" },
  { value: "project3", label: "Project 3" },
];

const CROP_TYPE_OPTIONS = [
  { value: "type1", label: "Type 1" },
  { value: "type2", label: "Type 2" },
  { value: "type3", label: "Type 3" },
];

const StepThreeForm = () => {
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedCropType, setSelectedCropType] = useState("");
  const [versionNumber, setVersionNumber] = useState(1);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const increaseVersion = () => setVersionNumber((prev) => prev + 1);
  const decreaseVersion = () =>
    setVersionNumber((prev) => Math.max(prev - 1, 1));

  const renderSelectOptions = (options) =>
    options.map((option) => (
      <MenuItem key={option.value} value={option.value} sx={{color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>
        {option.label}
      </MenuItem>
    ));

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 2,
        borderRadius: 1,
        background: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1,
        boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
      }}
    >
      <FormControl sx={{ mb: 2, width: { xs: "100%", md: "40%" }}}>
        <Typography sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, mb: 1 }}>
          Select Project(s)
        </Typography>
        <Select
          labelId="select-project-label"
          id="select-project"
          value={selectedProject}
          onChange={handleChange(setSelectedProject)}
          MenuProps={{
            PaperProps: {
              sx: {
                background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3,
                "& .MuiMenuItem-root": {
          color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, 
          "&:hover": {
            backgroundColor: isDarkTheme ? darkTheme.textColorLayer3 : lightTheme.textColorLayer3, 
          },
        },
              },
            },
          }}
          sx={{ backgroundColor: isDarkTheme  ? darkTheme.backgroundColorLayer2 : lightTheme.backgroundColorLayer2 , height: 40, "& .MuiSelect-icon": {
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          },
          color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
          border: '1px solid', 
          borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
          "&:hover": {
            borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
          },
        }}
          displayEmpty
        >
          <MenuItem value="" sx={{color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1}}>Select project</MenuItem>
          {renderSelectOptions(PROJECT_OPTIONS)}
        </Select>
      </FormControl>

      <FormControl sx={{ mb: 2, width: { xs: "100%", md: "40%" }} }>
        <Typography sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, mb: 1 }}>
          Crop Type
        </Typography>
        <Select
          labelId="select-crop-type-label"
          id="select-crop-type"
          value={selectedCropType}
          onChange={handleChange(setSelectedCropType)}
          MenuProps={{
            PaperProps: {
              sx: {
                background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3,
                "& .MuiMenuItem-root": {
          color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, 
          "&:hover": {
            backgroundColor: isDarkTheme ? darkTheme.dropdownHoverColorLayer3 : lightTheme.dropdownHoverColorLayer3, 
          },
        },
              },
            },
          }}
          sx={{ backgroundColor: isDarkTheme  ? darkTheme.backgroundColorLayer2 : lightTheme.backgroundColorLayer2 , height: 40, "& .MuiSelect-icon": {
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          },
          color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
          border: '1px solid', 
          borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
          "&:hover": {
            borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
          },
        }}
          displayEmpty
        >
          <MenuItem value="" sx={{color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>Select Crop</MenuItem>
          {renderSelectOptions(CROP_TYPE_OPTIONS)}
        </Select>
      </FormControl>
      <Typography sx={{ fontWeight: 700, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, mb: 1 }}>
        Version Number
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button
          variant="contained"
          onClick={decreaseVersion}
          color="#DFDFDF"
          sx={{
            backgroundColor: isDarkTheme ? darkTheme.buttonBackgroundLayer2 : lightTheme.buttonBackgroundLayer2 ,
          }}
          startIcon={<RemoveCircleOutlineOutlinedIcon sx={{
            color: isDarkTheme ? darkTheme.iconColor4 : lightTheme.iconColor4 ,
          }}/>}
        />
        <input
          type="number"
          value={versionNumber}
          readOnly
          style={{
            textAlign: "center",
            width: 60,
            color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
            border: "1px solid",
            borderColor: isDarkTheme ? darkTheme.borderColorLayer1 : lightTheme.borderColorLayer1,
            backgroundColor: isDarkTheme ? darkTheme.insideCardBackgroundColorLayer5 : lightTheme.insideCardBackgroundColorLayer5,
            paddingY:5
          }}
        />
        <Button
          variant="contained"
          onClick={increaseVersion}
          color="#DFDFDF"
          sx={{
            backgroundColor: isDarkTheme ? darkTheme.buttonBackgroundLayer2 : lightTheme.buttonBackgroundLayer2 ,
          }}
          startIcon={<AddCircleOutlineOutlinedIcon sx={{
            color: isDarkTheme ? darkTheme.iconColor4 : lightTheme.iconColor4 ,
          }}/>}
        />
      </Box>
    </Box>
  );
};
export default StepThreeForm;
