import React from "react";
import { Box,
  Typography,
  Card,
  Grid, } from "@mui/material";
import "../MobileScreenDocument.css";
import Button from "@mui/material/Button";
import potato from "../../../assets/potato.png";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { useSelector } from "react-redux";
export default function MobileDocument() {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <div>
      <Box
        sx={{
        display: 'flex',  
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 'var(--Spacing-spacing-16, 16px)',
        alignSelf: 'stretch',
        padding:'16px 0px',
        backgroundColor: isDarkTheme ? darkTheme.backgroundColorLayer1 : lightTheme.backgroundColorLayer1 ,
        }}
      >
        <Box
           sx={{
            display: 'flex',
            padding: 'var(--Spacing-spacing-16, 16px)',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 'var(--Spacing-spacing-24, 24px)', 
            alignSelf: 'stretch',
          }}
        >
          <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 'var(--Spacing-spacing-24, 24px)', 
        alignSelf: 'stretch',
      }}>

          <Typography
            sx={{
              color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2 ,
              fontFeatureSettings: "'liga' off, 'clig' off", 
              fontFamily: 'var(--Typography-Font-Family-Heading, Inter)', 
              fontSize: 'var(--Font-Size-H4, 28px)', 
              fontStyle: 'normal', 
              fontWeight: 700, 
              lineHeight: 'var(--Line-Height-H4, 40px)', 
            }}
          >
            Document Title
          </Typography>
          <Button
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch',
              borderRadius: 'var(--Corner-radius-effect-corner-3, 24px)', 
              background: 'var(--Tokens-Button-Primary, #005CBC)', 
            }}
          >
            <Box  sx={{
        display: 'flex',
        height: 'var(--Spacing-spacing-40, 40px)', 
        padding: 'var(--Spacing-spacing-03, 8px) var(--Spacing-spacing-05, 24px)', 
        justifyContent: 'center', 
        alignItems: 'center', 
        gap: 'var(--Spacing-spacing-03, 8px)', 
      }}>

            <Typography
              sx={{
                color: 'var(--Colors-White, #FFF)', 
                fontFamily: 'var(--Typography-Font-Family-Body, Inter)',
                fontSize: 'var(--Font-Size-Body, 16px)', 
                fontStyle: 'normal', 
                fontWeight: 700, 
                lineHeight: 'var(--Line-Height-Body, 22px)', 
                textTransform:'capitalize'
              }}
            >
              Open document
            </Typography>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white"/>
</svg>
            </Box>
          </Button>
          </Box>
        </Box>

        <Card className="SummaryCard" sx={{background:isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3}}>
          <Typography className="SummaryTitle" sx={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2 ,}}>Summary</Typography>
          <Box className="SummaryContentBox" sx={{background:isDarkTheme ? darkTheme.backgroundColorLayer1 : lightTheme.backgroundColorLayer1}}>
            <Typography className="SummaryContents" sx={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2}}>
              PepsiCo understands the critical role agriculture plays in all
              aspects of our business. Our products depend on a safe,
              high-quality, and affordable supply of agricultural raw materials
              to meet the demands of our business.: PepsiCo understands the
              critical role agriculture plays in all aspects of our business.
              Our products depend on a safe, high-quality, and affordable supply
              of agricultural raw materials to meet the demands of our
              business.:PepsiCo understands the critical role agriculture plays
              in all aspects of our business. Our products depend on a safe,
              high-quality, and affordable supply of agricultural raw materials
              to meet the demands of our business.:PepsiCo understands the
              critical role agriculture plays in all aspects of our business.
              Our products depend on a safe, high-quality, and affordable supply
              of agricultural raw materials to meet the demands of our
              business.:PepsiCo understands the critical role agriculture plays
              in all aspects of our business. Our products depend on a safe,
              high-quality, and affordable supply of agricultural raw materials
              to meet the demands of our business.:PepsiCo understands the
              critical role agriculture plays in all aspects of our business
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "var(--Spacing-spacing-16, 16px)",
                flexWrap: 'wrap'
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="LogoButton"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M18.3337 7.69984L12.342 7.18317L10.0003 1.6665L7.65866 7.1915L1.66699 7.69984L6.21699 11.6415L4.85033 17.4998L10.0003 14.3915L15.1503 17.4998L13.792 11.6415L18.3337 7.69984ZM10.0003 12.8332L6.86699 14.7248L7.70033 11.1582L4.93366 8.75817L8.58366 8.4415L10.0003 5.08317L11.4253 8.44984L15.0753 8.7665L12.3087 11.1665L13.142 14.7332L10.0003 12.8332Z"
                      fill="#005CBC"
                    />
                  </svg>
                  <Typography
                    className="LogoContent"
                  >
                    Favorite
                  </Typography>
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="LogoButton"
                >
                  <Typography
                    className="LogoContent"
                  >
                    Share
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15 13.3998C14.3667 13.3998 13.8 13.6498 13.3667 14.0415L7.425 10.5832C7.46667 10.3915 7.5 10.1998 7.5 9.99984C7.5 9.79984 7.46667 9.60817 7.425 9.4165L13.3 5.9915C13.75 6.40817 14.3417 6.6665 15 6.6665C16.3833 6.6665 17.5 5.54984 17.5 4.1665C17.5 2.78317 16.3833 1.6665 15 1.6665C13.6167 1.6665 12.5 2.78317 12.5 4.1665C12.5 4.3665 12.5333 4.55817 12.575 4.74984L6.7 8.17484C6.25 7.75817 5.65833 7.49984 5 7.49984C3.61667 7.49984 2.5 8.6165 2.5 9.99984C2.5 11.3832 3.61667 12.4998 5 12.4998C5.65833 12.4998 6.25 12.2415 6.7 11.8248L12.6333 15.2915C12.5917 15.4665 12.5667 15.6498 12.5667 15.8332C12.5667 17.1748 13.6583 18.2665 15 18.2665C16.3417 18.2665 17.4333 17.1748 17.4333 15.8332C17.4333 14.4915 16.3417 13.3998 15 13.3998ZM15 3.33317C15.4583 3.33317 15.8333 3.70817 15.8333 4.1665C15.8333 4.62484 15.4583 4.99984 15 4.99984C14.5417 4.99984 14.1667 4.62484 14.1667 4.1665C14.1667 3.70817 14.5417 3.33317 15 3.33317ZM5 10.8332C4.54167 10.8332 4.16667 10.4582 4.16667 9.99984C4.16667 9.5415 4.54167 9.1665 5 9.1665C5.45833 9.1665 5.83333 9.5415 5.83333 9.99984C5.83333 10.4582 5.45833 10.8332 5 10.8332ZM15 16.6832C14.5417 16.6832 14.1667 16.3082 14.1667 15.8498C14.1667 15.3915 14.5417 15.0165 15 15.0165C15.4583 15.0165 15.8333 15.3915 15.8333 15.8498C15.8333 16.3082 15.4583 16.6832 15 16.6832Z"
                      fill="#005CBC"
                    />
                  </svg>
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="LogoButton"
                >
                  <Typography
                    className="LogoContent"
                  >
                    Like
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M7.49967 17.5002H14.9997C15.6913 17.5002 16.283 17.0835 16.533 16.4835L19.0497 10.6085C19.1247 10.4168 19.1663 10.2168 19.1663 10.0002V8.3335C19.1663 7.41683 18.4163 6.66683 17.4997 6.66683H12.2413L13.033 2.8585L13.058 2.59183C13.058 2.25016 12.9163 1.9335 12.6913 1.7085L11.808 0.833496L6.31634 6.32516C6.01634 6.62516 5.83301 7.04183 5.83301 7.50016V15.8335C5.83301 16.7502 6.58301 17.5002 7.49967 17.5002ZM7.49967 7.50016L11.1163 3.8835L9.99967 8.3335H17.4997V10.0002L14.9997 15.8335H7.49967V7.50016ZM0.833008 7.50016H4.16634V17.5002H0.833008V7.50016Z"
                      fill="#005CBC"
                    />
                  </svg>
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="LogoButton"
                >
                  <Typography
                    className="LogoContent"
                  >
                    Dislike
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M12.4997 2.5H4.99967C4.30801 2.5 3.71634 2.91667 3.46634 3.51667L0.949674 9.39167C0.874674 9.58333 0.833008 9.78333 0.833008 10V11.6667C0.833008 12.5833 1.58301 13.3333 2.49967 13.3333H7.75801L6.96634 17.1417L6.94134 17.4083C6.94134 17.75 7.08301 18.0667 7.30801 18.2917L8.19134 19.1667L13.683 13.675C13.983 13.375 14.1663 12.9583 14.1663 12.5V4.16667C14.1663 3.25 13.4163 2.5 12.4997 2.5ZM12.4997 12.5L8.88301 16.1167L9.99967 11.6667H2.49967V10L4.99967 4.16667H12.4997V12.5ZM15.833 2.5H19.1663V12.5H15.833V2.5Z"
                      fill="#005CBC"
                    />
                  </svg>
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>

        <Card className="SummaryCard" sx={{background:isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3, width: '100%'}}>
          <Typography className="SummaryTitle" sx={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2 ,}}>Details</Typography>
          <Box
            sx={{
              display: "flex",
              padding: "var(--Spacing-spacing-16, 16px)",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "var(--Spacing-spacing-12, 12px)",
              alignSelf: "stretch",
              background:isDarkTheme ? darkTheme.backgroundColorLayer1 : lightTheme.backgroundColorLayer1
            }}
          >
            <img src={potato} alt="" />
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    View count
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    123
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Likes count
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    123
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Content level
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Advanced
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Document type
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    PDF
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Language
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    English
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Version
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Advanced
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Edited by SME
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Maria Alexander
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Upload date
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    DD-MM-YYYY
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                  Uploaded by
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                  Maria Alexander
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                  Reviewed date
                   </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                  DD-MM-YYYY
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                  Legal review
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                  Yes
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box className="DetailCardBox" sx={{ background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3,}}>
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Subjects
                  </Typography>
                  <Typography className="DetailCardGridSecondContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Variety Development and Factsheets
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{
                width: '100%'
              }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "260px",
                    height: "65px",
                    padding:
                      "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-16, 16px)",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "var(--Spacing-spacing-8, 8px)",
                    borderRadius:
                      "var(--Corner-radius-corner-radius-small, 8px)",
                    background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer3 : lightTheme.insideCardBackgroundColorLayer3
                  }}
                >
                  <Typography className="DetailCardGridFirstContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4}}>
                    Tags
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "var(--Spacing-spacing-12, 12px)",
                    }}
                  >
                    <Typography className="TagsContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer4 : lightTheme.insideCardBackgroundColorLayer4 }}>
                      Sustainability
                    </Typography>
                    <Typography className="TagsContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer4 : lightTheme.insideCardBackgroundColorLayer4 }}>Potato</Typography>
                    <Typography className="TagsContent" sx={{color : isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, background : isDarkTheme ? darkTheme.insideCardBackgroundColorLayer4 : lightTheme.insideCardBackgroundColorLayer4 }}>Grower</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card className="SummaryCard" sx={{background:isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3}}>
          <Typography className="SummaryTitle" sx={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2 ,}}>Disclaimer</Typography>
          <Box className="SummaryContentBox" sx={{background:isDarkTheme ? darkTheme.backgroundColorLayer1 : lightTheme.backgroundColorLayer1}}>
            <Typography className="SummaryContents" sx={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2}}>
              PepsiCo understands the critical role agriculture plays in all
              aspects of our business. Our products depend on a safe,
              high-quality, and affordable supply of agricultural raw materials
              to meet the demands of our business.: PepsiCo understands the
              critical role agriculture plays in all aspects of our business.
              Our products depend on a safe, high-quality, and affordable supply
              of agricultural raw materials to meet the demands of our
              business.:PepsiCo understands the critical role agriculture plays
              in all aspects of our business. Our products depend on a safe,
              high-quality, and affordable supply of agricultural raw materials
              to meet the demands of our business.:PepsiCo understands the
              critical role agriculture plays in all aspects of our business.
              Our products depend on a safe, high-quality, and affordable supply
              of agricultural raw materials to meet the demands of our
              business.:PepsiCo understands the critical role agriculture plays
              in all aspects of our business. Our products depend on a safe,
              high-quality, and affordable supply of agricultural raw materials
              to meet the demands of our business.:PepsiCo understands the
              critical role agriculture plays in all aspects of our business
            </Typography>
          </Box>
        </Card>
      </Box>
    </div>
  );
}
