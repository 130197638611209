import { styled } from "styled-components";
import { Card } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import image1 from "../../assets/spotlight1.png";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04, 16px);
  background-color: var(--blue50);
  box-shadow: 0 0 24px rgba(0, 92, 188, 0.08);
  padding: 1.5rem;
  border-radius: 8px;

  &.border {
    border: 1px solid var(--blue500);
    box-shadow: none;
  }
`;
const StyledBanner = styled.div`
  display: flex;
  flex-direction: column; /* Default for small screens */
  padding: 16px;
  gap: 1rem;
  border-radius: 8px;
  width: fit-content; /* Default width for smaller screens */
  height: auto; /* Default height for smaller screens */

  @media (min-width: 900px) {
    flex-direction: row; /* Switch to row on larger screens */
  }
`;
const AgricultureSection = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <StyledCard className="shadow-xl" sx={{background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3}}>
      <div className=" flex justify-between items-center">
        <p className="font-inter text-xl font-bold leading-[1.4]" style={{color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1}}>
          Agriculture
        </p>
        <p className="font-bold ">
          <span style={{ color: "#1976d2" }}>View all</span>
          <ArrowForwardIcon
            color="primary"
            fontSize="medium"
            className="pl-2 font-bold"
          />
        </p>
      </div>
      <div className="flex flex-col w-full gap-4 md:flex-row lg:flex-row ">
        <StyledBanner className="shadow-lg" style={{background: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1}}>
          <div className="w-full h-full lg:w-1/2">
            <img className="w-full h-full" alt="image" src={image1} />
          </div>
          <div className="flex flex-col w-full lg:w-1/2 gap-4">
            <span className="font-inter text-base font-bold leading-[1.4]  w-full">
              <h4 style={{color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1}}> Sustainability</h4>
            </span>
            <span className="font-semibold w-full  h-auto  flex-wrap " style={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2}}>
              Pepsico understands the critical role agriculture plays in all
              aspects of our business. Our products depend on a safe,
              high-quality, and affordable supply of agricultural raw materials
              to meet the demands of our business.
            </span>
            <span className="font-bold">
              <span style={{ color: "#1976d2" }}>Learn More</span>
              <ArrowForwardIcon
                color="primary"
                fontSize="medium"
                className="pl-2 font-bold"
              />
            </span>
          </div>
        </StyledBanner>
        <StyledBanner className="shadow-lg" style={{background: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1}}>
          <div className="w-full h-full lg:w-1/2">
            <img className="w-full h-full" alt="image" src={image1} />
          </div>
          <div className="flex flex-col w-full lg:w-1/2 gap-4">
            <span className="font-inter text-base font-bold leading-[1.4]  w-full">
              <h4 style={{color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1}}> Variety Information</h4>
            </span>
            <span className="font-semibold w-full  h-auto  flex-wrap " style={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2}}>
              Pepsico understands the critical role agriculture plays in all
              aspects of our business. Our products depend on a safe,
              high-quality, and affordable supply of agricultural raw materials
              to meet the demands of our business.
            </span>
            <span className="font-bold">
              <span style={{ color: "#1976d2" }}>Learn More</span>
              <ArrowForwardIcon
                color="primary"
                fontSize="medium"
                className="pl-2 font-bold"
              />
            </span>
          </div>
        </StyledBanner>
      </div>
    </StyledCard>
  );
};

export default AgricultureSection;
