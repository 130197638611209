import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AdminstrationLayout from "./pages/AdminstrationLayout";
import Container from "./layout/Container";
import PrivateRoute from "./hoc/PrivateRoute";
import { createTheme, ThemeProvider } from "@mui/material";
import InternalUpload from "./components/Upload/InternalUpload";
import ExternalUpload from "./components/Upload/ExternalUpload";
import DocmentLayout from "./pages/DocumentLayout";
const theme = createTheme();
function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Container />}>
              <Route path="/" element={<Home />} />
              <Route path="/admin" element={<AdminstrationLayout />} />
              <Route path="/internal-upload" element={<InternalUpload />} />
              <Route path="/external-upload" element={<ExternalUpload />} />
              <Route path="/document" element={<DocmentLayout />} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
