import React, { useState } from "react";
import { Box } from "@mui/material";
import FormStepper from "./FormStepper";
import NavigationButtons from "./NavigationButtons";
import ExternalStepOneForm from "./ExternalStepOneForm";
import ExternalStepTwoForm from "./ExternalStepTwoForm.jsx";
import ExternalStepThreeForm from "./ExternalStepThreeForm";

const steps = ["Step 1", "Step 2", "Step 3"];

const ExternalUpload = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <ExternalStepOneForm />;
      case 1:
        return <ExternalStepTwoForm />;
      case 2:
        return <ExternalStepThreeForm />;
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Box sx={{ width: "90%", margin: "auto", mt: 2, mb: 2 }}>
        <FormStepper steps={steps} activeStep={activeStep} />
        <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
      </Box>

      <NavigationButtons
        activeStep={activeStep}
        totalSteps={steps.length}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </>
  );
};

export default ExternalUpload;
