import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../theme/Theme";

const ListComponent = ({ title }) => {
  const listItems = Array.from({ length: 5 });
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1,
        boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingBottom: "8px",
        }}
      >
        <Typography
          component="span"
          variant="body2"
          sx={{ color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, display: "inline", fontWeight: "700" }}
        >
          {title}
        </Typography>
        <Link href="#" variant="body2" sx={{ textDecoration: "none" }}>
          View all
        </Link>
      </Box>

      <List sx={{ width: "100%" }}>
        {listItems.map((_, index) => (
          <ListItem
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              width: "100%",
              boxShadow: "0px -1px 0px 0px #DFDFDF inset",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingBottom: "8px",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  variant="square"
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography sx={{ fontWeight: "700",color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>
                    How to Plant PepsiCo Potato Variety #1 - Introduction
                  </Typography>
                }
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        display: "inline",
                        color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2
                      }}
                    >
                      This is the description This is the description This is
                      the description This is the description This is the
                      description ...
                    </Typography>
                  </>
                }
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                component="span"
                variant="body2"
                sx={{ color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2, display: "inline" }}
              >
                15 minutes read
              </Typography>
              <Link href="#" variant="body2" sx={{ textDecoration: "none" }}>
                Learn More
              </Link>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ListComponent;
