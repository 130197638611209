import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Search, Icon } from "pepsico-ds";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";

const InvitationHistory = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        flex: "1 0 0",
        border: "2px solid",
        borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-8, 8px)",
          alignItems: "center",
          alignSelf: "stretch",
          background: isDarkTheme ? darkTheme.backgroundColorLayer2 : lightTheme.backgroundColorLayer2 ,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: "1 0 0",
            border: "2px solid",
            borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
          }}
        >
          <Typography
            sx={{
              color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontSize: "var(--Font-Size-H5, 24px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-H5, 32px)",
            }}
          >
            Invitation history
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-8, 8px)",
            alignItems: "center",
          }}
        >
          <Search
            className="flex justify-center"
            onSearch={() => {}}
            onSelectOption={() => {}}
            onUpdate={() => {}}
            placeholderText="Type a search keyword"
            size="medium"
            isDark={isDarkTheme}
          />
        </Box>
        <Button
          variant="contained"
          sx={{
            display: "flex",
            padding:
              "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-4, 4px)",
            justifyContent: "center",
            alignItems: "center",
            gap: "-4px",
            borderRadius: "var(--Corner-radius-corner-radius-xsmall, 4px)",
            background: "var(--Tokens-Interaction-Active, #005CBC)",
            color: "white",
          }}
        >
          <Icon
            alt="alt value"
            className="text-white"
            icon="filter_list"
            size="medium"
            isDark={isDarkTheme}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default InvitationHistory;
