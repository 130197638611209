import React, { useEffect } from "react";
import HomePageCarousal from "./../components/HomePage/HomePageCarousal";
import SpotLight from "../components/Spotlight";
import Popover from "../components/Popover";
import AgricultureSection from "../components/HomePage/AgricultureSection";
import { Box } from "@mui/material";
import { darkTheme, lightTheme } from "../theme/Theme";
import { useSelector } from "react-redux";

const Home = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box sx={{background: isDarkTheme ? darkTheme.backgroundColorLayer1 : lightTheme.backgroundColorLayer1 }}>
      <HomePageCarousal />
      <Box
        sx={{
          width: "100%",
          marginTop: 4,
          marginBottom: 4,
          boxShadow: "0px 2px 24px 8px rgba(0, 0, 0, 0.2)",
          padding: "32px",
          background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3
        }}
      >
        <SpotLight />
      </Box>
      <AgricultureSection />
      <Popover />
    </Box>
  );
};

export default Home;
