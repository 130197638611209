import { Carousel } from "pepsico-ds";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import styled from "styled-components";
import spotlight1 from './../assets/spotlight1.png'
import spotlight2 from './../assets/spotlight2.png'
import spotlight3 from './../assets/spotlight3.png'
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../theme/Theme";
const StyledCard = styled(Card)`
  width: 32.8%;
  height: auto;
  padding: 8px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const CardContainer = styled(Card)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const ImageContainer = styled(CardMedia)`
  height: 200px;
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
`;
const LearnMoreLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #1976d2;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const CardComponent = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <CardContainer sx={{background: isDarkTheme ? darkTheme.carosalBackground : lightTheme.carosalBackground}}>
      <StyledCard sx={{background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3}}>
        <ImageContainer
          image={spotlight1}
          title={"2024 Frito-Forum #3 - Digital Journey"}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 3.5,
            padding: "8px 0px",
            color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1
          }}
        >
          <Typography variant="h5" component="div">
            {"2024 Frito-Forum #3 - Digital Journey"}
          </Typography>
          <Typography variant="body2" sx={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2 }}>
            {
              "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and..."
            }
          </Typography>
          <LearnMoreLink href="#">
            Learn more <ArrowForward fontSize="small" />
          </LearnMoreLink>
        </CardContent>
      </StyledCard>
      <StyledCard sx={{background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3}}>
        <ImageContainer
          image={spotlight2}
          title={"2024 Frito-Forum #3 - Digital Journey"}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 3.5,
            padding: "8px 0px",
            color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1
          }}
        >
          <Typography variant="h5" component="div">
            {"2024 Frito-Forum #3 - Digital Journey"}
          </Typography>
          <Typography variant="body2" sx={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2 }}>
            {
              "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and..."
            }
          </Typography>
          <LearnMoreLink href="#">
            Learn more <ArrowForward fontSize="small" />
          </LearnMoreLink>
        </CardContent>
      </StyledCard>
      <StyledCard sx={{background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3}}>
        <ImageContainer
          image={spotlight3}
          title={"2024 Frito-Forum #3 - Digital Journey"}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "8px 0px",
            gap: 3.5,
            color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1
          }}
        >
          <Typography variant="h5" component="div">
            {"2024 Frito-Forum #3 - Digital Journey"}
          </Typography>
          <Typography variant="body2" sx={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2 }}>
            {
              "PFNA will hold its 3rd Frito Forum of 2024 on May 29th on the topic of Digital Journey and..."
            }
          </Typography>
          <LearnMoreLink href="#">
            Learn more <ArrowForward fontSize="small" />
          </LearnMoreLink>
        </CardContent>
      </StyledCard>
    </CardContainer>
  );
};

const FilterComponent = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <FormControl variant="outlined" size="small" sx={{width: { xs: "100%",sm: "300px"},'& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
      },
      '&:hover fieldset': {
        borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2, 
      },
    },}} >
      <InputLabel id="filter-label" sx={{color:isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1}}>Filter by</InputLabel>
      <Select sx={{
     borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
    width: "100%",
    color:isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
    "& .MuiSelect-icon": {
        color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1, 
      },
  }}
  MenuProps={{
    PaperProps: {
      sx: {
        background: isDarkTheme ? darkTheme.cardBackgroundColorLayer3 : lightTheme.cardBackgroundColorLayer3,
      },
    },
  }} labelId="filter-label" label="Filter by" defaultValue="10">
        <MenuItem value={10} sx={{color:isDarkTheme &&'white'}}>Option 1</MenuItem>
        <MenuItem value={20} sx={{color:isDarkTheme &&'white'}}>Option 2</MenuItem>
        <MenuItem value={30} sx={{color:isDarkTheme &&'white'}}>Option 3</MenuItem>
      </Select>
    </FormControl>

  );
};

const TitleAndFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SpotLight = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box>
      <TitleAndFilterContainer>
        <Typography variant="h4" component="div" sx={{color:isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1}}>
          In the Spotlight
        </Typography>
        <FilterComponent />
      </TitleAndFilterContainer>
      <Carousel
        style={{
          backgroundColor: !isDarkTheme && "#fbfcfe",
          padding: "32px",
        }}
        itemData={[
          <CardComponent key={1} />,
          <CardComponent key={2} />,
          <CardComponent key={3} />,
        ]}
        isDark={isDarkTheme}
      />
    </Box>
  );
};

export default SpotLight;
