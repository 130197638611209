import React, { useState } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { useDropzone } from "react-dropzone";
import upload from "./../../assets/upload.png";
import ExternalUpload from "./../../assets/ExternalUpload.png";
import LinkIcon from "@mui/icons-material/Link";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

const ExternalStepOneForm = () => {
  const [contentType, setContentType] = useState("upload");
  const isDarkTheme = useSelector((state) => state.theme);
  const handleChange = (event) => {
    setContentType(event.target.value);
  };

  const onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [] },
  });

  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };

  const dropzoneStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px dashed #D7E5F8",
    background: isDarkTheme ? darkTheme.insideCardBackgroundColorLayer4 : lightTheme.insideCardBackgroundColorLayer4 ,
    padding: "16px",
    width: "100%",
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Box sx={baseBoxStyle}>
        <FormLabel component="legend" sx={{ color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>
          Content Type
        </FormLabel>
        <RadioGroup value={contentType} onChange={handleChange}>
          <FormControlLabel value="upload" control={<Radio 
          sx={{
          color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
        }}
        />} label="Upload" sx={{
          color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
        }} />
          <FormControlLabel value="link" control={<Radio sx={{
          color: isDarkTheme ? darkTheme.radioButtonLayer1 : lightTheme.radioButtonLayer1, 
        }} />} label="Link" sx={{
          color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
        }} />
          
        </RadioGroup>
        {contentType === "link" && (
          <TextField
          placeholder="Paste Link "
          sx={{
            borderRadius: "4px",
            width: { xs: "100%", md: "40%" },
            border:'1px solid',
            borderColor: isDarkTheme ? darkTheme.borderColorLayer1 : lightTheme.borderColorLayer1,
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
                },
                "&:hover fieldset": {
                  borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
                },
                "&.Mui-focused fieldset": {
                  borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
                },
              },
            "& .MuiInputBase-input": {
      color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1, 
    },
    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
      color: isDarkTheme ? darkTheme.iconColor2 : lightTheme.iconColor2,  
    },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
            ),
          }}
        />)}
      </Box>

      {contentType === "upload" && (
        <>
          <Box sx={{ mt: 2 }}>
            <Box sx={baseBoxStyle}>
              <FormLabel component="legend" sx={{ color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>
                Document
              </FormLabel>
              <Box {...getRootProps()} sx={dropzoneStyle}>
                <img src={upload} alt="Document Icon" />
                <input {...getInputProps()} />
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: { xs: "center" },
                    width:{xs:"80%"}
                  }}
                >
                  {isDragActive
                    ? "Drop the PDF file here..."
                    :  <p style={{color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1}}>
                    Drag and drop your file here <br /> (only .jpg, .png, .svg, .pdf)
                  </p>}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    mt: 2,
                    borderRadius: "24px",
                    border: "1px solid #005CBC",
                    py: 1,
                    px: 2,
                  }}
                  startIcon={<UploadOutlinedIcon />}
                >
                  Upload File
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Box sx={baseBoxStyle}>
              <FormLabel component="legend" sx={{ color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>
                Thumbnail
              </FormLabel>
              <Box
                sx={{
                  ...dropzoneStyle,
                  padding: "0", 
                  position: "relative",
                  background: `url(${ExternalUpload}) no-repeat center`,
                  backgroundSize: "cover", 
                  height: "200px", 
                }}
              >
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ExternalStepOneForm;
