import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedPage: "defaultPage",
};

const adminPageSlice = createSlice({
  name: "adminpage",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.selectedPage = action.payload;
    },
  },
});
export const { setPage } = adminPageSlice.actions;
export default adminPageSlice.reducer;
